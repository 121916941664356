.homeHeroContainer {
  width: 100%;
  height: 100vh;
  background: url('https://develop.corporate-setup.com/web-assets/img/backgrounds/bg2.jpeg');
  overflow: hidden;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;

  font-family: 'Nunito Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  margin: 0;
  text-align: left;
  color: #718096;
}
.homeHeroMask {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease;
  background-color: green !important;
  opacity: 0.4;
  background: no-repeat center center/cover;
}

.homeHeroTxt {
  padding: 210px 470px 150px 150px;
  color: white;
}
.homeHeroTxtH1 {
  font-size: 40px;
  font-weight: 200;
}
.homeHeroTxtP {
  font-size: 18px;
}
.homeHeroTxtButton {
  background-color: #228b22;
  border: none;
  padding: 20px 25px;
  color: white;
  border-radius: 10px;
  font-size: 18px;
  margin-top: 30px;
}
.homeHeroTxtButton:hover {
  background-color: rgb(10, 156, 148);
  cursor: pointer;
}
