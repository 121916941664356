.cardContainer {
  margin: auto;
  width: 60%;
  padding: 50px 0 30px 0;
}
.cardWrapper {
  box-shadow: 0px 4px 17px rgba(98, 190, 203, 0.15);
  border-radius: 10px;
  background-color: white;
  padding: 50px 0 50px 0;
}
.loginHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loginHeaderInfo {
  display: flex;
  align-items: center;
}
.loginText {
  font-size: 25px;
  font-weight: 500;
  color: rgba(36, 36, 66, 0.944);
}
.loginIcon {
  font-size: 25px;
  color: rgba(36, 36, 66, 0.944);
  padding-left: 10px;
}
.loginDesc {
  font-weight: 12px;
  font-weight: 400;
  padding: 15px;
  color: gray;
}
.cardButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
}
.checkboxWrapper {
  padding: 20px 0 10px 160px;
  color: rgba(36, 36, 66, 0.944);
}
.fPassword {
  color: #008aff;
}
