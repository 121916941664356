.homeTestimonies {
  display: grid;
  width: 100%;
  height: 100%;
  place-items: center;
  padding-bottom: 5rem;
}
.homeTestimoniesWrapper {
  width: 100%;
  height: 80vh;
  background: #008000;
  padding-top: 50px;
}
.testimoniesT {
  display: grid;
  place-items: center;
  padding: 8rem 0;
}
.testimoniesTH1,
.testimoniesTH2 {
  color: #fff;
}
.testimoniesTH1 {
  font-weight: 600;
  font-size: calc(1.375rem + 1.5vw);
}
.testimoniesTH2 {
  font-weight: 600;
  font-size: 30px;
  padding: 2rem 0;
  padding-bottom: 4rem;
}
.testimoniesBtn {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  background: #fff;
  color: #273444;
  border-radius: 10px;
  transform: translateY(0);
  transition: 250ms ease all;
  cursor: pointer;
}
.testimoniesBtn:hover {
  transform: translateY(-1rem);
}
.testimoniesBtnP {
  padding-left: 10px;
  font-size: 25px;
  color: #272444;
}
.testimoniesBtnIcon {
  font-size: 23px;
  padding: 10px;
  color: #272444;
}
.testimoniesContentWrapper {
  display: grid;
  place-items: center;
  margin-top: -10rem;
}
.testimoniesContent {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.maxWrapper {
  max-width: 1750px;
  width: 100%;
}
