.homeCardContainer {
  width: 35rem;
  height: 100%;
  margin: 1rem;
  padding: 3rem;
  border-radius: 10px;
  background: #ebf9f4;
}
.homeCardContainer:nth-child(1) {
  margin-top: 0;
}
.homeCardContainer:nth-child(2) {
  margin-top: 3rem;
}
.homeCardContainer:nth-child(3) {
  margin-top: 6rem;
}

.homeCardImgContainer {
  display: grid;
  place-items: center;
}
.homeCardImg {
  width: 90%;
  height: 100%;
  max-width: 250px;
  max-height: 250px;
  border-radius: 10px;
}
.homeCardT {
  padding: 1rem 0;
  font-size: 23px;
  font-weight: 600;
  color: #171347 !important;
}
.homeClassP {
  font-size: 16px;
  color: #171347 !important;
  line-height: 30px;
  opacity: 0.6;
}
