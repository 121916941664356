.registerContainer {
  width: 100%;
  height: 100vh;
  /* background-color: green; */
  background: url('https://develop.corporate-setup.com/web-assets/img/account/login-background.jpg');
  overflow: hidden;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
}
.notRegister {
  width: 100%;
  text-align: center;
}
.notRegisterText {
  color: white;
}
a {
  text-decoration: none;
  color: rgb(1, 138, 255);
}
.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease;
  background-color: green;
  opacity: 0.8;
}
