.card {
  padding: 3.5rem;
  width: 25rem;
  border-radius: 1rem;
  height: 100%;
  margin: 2rem;
  transition: 150ms ease-in;
  transform: translateY(0);
}
.card:hover {
  transform: translateY(-2rem);
}
.card img {
  height: 100%;
  width: 100%;
  max-width: inherit;
  max-height: 40rem;
}
.card-content {
  display: flex;
  padding-bottom: 12rem;
  flex: 1;
}
.card-content-btn {
  padding: 4px 8px;
  background: red;
  display: inline-block;
  border-radius: 2rem;
  font-size: 1.5rem;
  align-self: start;
}
.card-content-main {
  flex: 1;
  padding-left: 2rem;
  font-size: 1.6rem;
}
