.homeInfoContainer {
  display: grid;
  width: 92%;
  place-items: center;
}
.homeInfoContainerWrapper {
  width: 80%;
  max-width: 1400px;
  display: flex;
}
