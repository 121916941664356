.navbar {
  height: 80px;
  background-color: rgb(0, 128, 0);
  display: flex;
  justify-content: center;
}
.navContainer {
  width: 100%;
  max-width: 1024px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  font-weight: 500;
}
.navButton {
  margin-left: 20px;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  color: #003580;
}

.links {
  padding: 10px;
  cursor: pointer;
}

.links ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.links li {
  padding: 15px;
}

.links a {
  color: whitesmoke;
  text-decoration: none;
}

.links a:hover,
.links a:active {
  color: white;
  cursor: pointer;
}
