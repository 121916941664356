.homeInfo2Container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  padding-bottom: 100px;
}

.homeInfo2ContainerR {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 100px;
  padding-right: 80px;
}
.homeInfo2Img {
  /* border-radius: 5px; */
}

.homeInfo2ContainerL {
  flex: 1;
  margin: auto;
  padding-right: 150px;
}
.homeInfo2ContainerLWrapper {
  border-radius: 10px;
  background-color: white;
  padding: 50px 0 50px 0;
}
.homeInfo2H {
  font-size: 29px;
  color: #152c5b;
  padding-bottom: 30px;
  font-weight: 500;
}
.homeInfo2P {
  font-size: 18px;
  color: #718096;
  line-height: 30px;
  padding-bottom: 50px;
}
.homeInfo2PInfo {
  /* padding-bottom: 120px; */
  padding: 30px;
}
.homeInfo2Li {
  padding-left: 0;
  list-style: none;
  padding-bottom: 20px;
  font-size: 16px;
  color: #152c5b;
  font-weight: 500;
}
.homeInfo2Shape {
  display: inline-flex;
  width: 2rem;
  height: 2rem;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.375rem;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  color: #32ac86;
  border: 3px solid rgba(130, 214, 188, 0.5);
  margin-right: 1rem !important;
}
.homeInfo2ShapeIcon {
  color: #32ac86;
  display: inline-flex;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.375rem;
  align-items: center;
  justify-content: center;
}
