.footerContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #1f2d3d !important;
  color: white;
  padding-top: 60px;
}
.footer1A {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  margin-right: 50px;
}
.footer1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
}
.footer1Right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.descH {
  font-size: 27px;
  line-height: 150%;
  letter-spacing: 0.01em;
  font-weight: 600;
  color: #eaecf3 !important;
  padding-right: 200px;
  margin-bottom: 25px;
}
.descP {
  font-size: 18px;
  color: #fff !important;
  padding-right: 150px;
}
.signIn1 {
  background-color: white;
  border: none;
  padding: 18px 40px;
  color: #273444;
  border-radius: 5px;
  font-size: 16px;
  margin: 30px;
}
.signIn2 {
  background-color: #228b22;
  border: none;
  padding: 18px 40px;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 100px;
}

.footer1Left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer2 {
  display: flex;
  padding-top: 60px;
  padding-bottom: 60px;
}
.footer2L {
  flex: 2;
  margin-left: 100px;
}
.footer2LLogo {
  width: 100px;
  height: 50px;
  object-fit: contain;
}
.footerH {
  font-size: 16px;
  font-weight: 450;
  color: rgba(255, 255, 255, 0.8);
  padding-right: 150px;
  line-height: 30px;
  padding-bottom: 30px;
}
.footerP {
  font-size: 0.875rem !important;
  opacity: 0.8;
  padding-right: 190px;
  line-height: 20px;
  padding-bottom: 10px;
  color: rgba(255, 255, 255, 0.8);
}
.footer2R {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 100px;
}
.contactH4 {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
  color: #eaecf3;
}
.contactUl {
  list-style: none;
}
.contactLi {
  font-size: 14px;
  font-weight: 200;
  line-height: 180%;
  letter-spacing: 0.01em;
  color: #a0aec0;
  text-align: left;
  cursor: pointer;
}

.footer3B {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.horizon1 {
  background-color: rgba(255, 255, 255, 0.101);
  height: 0.1px;
  width: 80%;
  margin-bottom: 5px;
  transform: translateX(10%);
}
.horizon2 {
  background-color: rgba(255, 255, 255, 0.101);
  height: 0.1px;
  width: 75%;
  margin-bottom: 5px;
}

.footer3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer3Left {
  margin-left: 100px;
}
.footer3Right {
  display: flex;
  padding-right: 200px;
}
.paySecure {
  display: flex;
}
.copy {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.07em;
  color: rgb(165, 170, 177);
}
span {
  color: rgb(233, 205, 168);
}
.paySecureUl {
  list-style: none;
  display: flex;
}
.paySecureLi {
  font-size: 14px;
  font-weight: 450;
  line-height: 180%;
  letter-spacing: 0.01em;
  color: #a0aec0;
  text-align: left;
  padding: 15px;
  cursor: pointer;
}
