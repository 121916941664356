.getStartedContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  padding-bottom: 100px;
}
.getStartedContainerL {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 150px;
  padding-right: 80px;
}
.getStartedH {
  font-size: 70px;
  color: #152c5b;
  padding-bottom: 30px;
  font-weight: 500;
}
.getStartedSpan {
  color: #5cc9a7;
}
.getStartedP {
  font-size: 18px;
  color: #718096;
  line-height: 30px;
  padding-bottom: 50px;
}
.getStartedButton {
  width: 150px;
  background-color: #228b22;
  border: none;
  padding: 18px 10px;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 550;
  margin-right: 100px;
}
.getStartedButton:hover {
  background-color: rgb(3, 136, 130);
  cursor: pointer;
}
.getStartedContainerR {
  flex: 1;
  margin: auto;
  width: 60%;
  padding-right: 150px;
}
.getStartedContainerRWrapper {
  border-radius: 10px;
  background-color: white;
  padding: 50px 0 50px 0;
}
.getStartedImg {
  border-radius: 10px;
}
